@import 'commonsui/dist/css/_variables.scss';

.link {
	line-height: 40px;
}

.disabledLink {
	display: block;
	padding-top: 0.9rem;
	padding-bottom: 0.9rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	color: $nav-link-disabled-color;
	text-decoration: none;
	line-height: 40px;
	cursor: default;
}
