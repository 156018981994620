@import 'commonsui/dist/css/_variables.scss';

// .carousel {
// 	margin-top: -14px;
// 	display: flex;
// }

.carousel ol {
	justify-content: left;
	margin-left: 8%;
	margin-bottom: 3%;
}

.carousel li {
	display: inline-block;
	width: 8px;
	height: 8px;
	text-indent: -999px;
	border: 1px solid white;
	border-radius: 10px;
	cursor: pointer;
	opacity: 1;
}

.caption {
	text-align: left;
	bottom: 8vh;
}

.banner {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 335px;
	color: white;
	display: flex;
	align-items: center;
}
