.centeredCheckbox label {
	margin-top: 1px;
}

@media (max-width: 576px) {
	.centeredCheckbox {
		font-size: 15px;
	}

	.centeredCheckbox label {
		margin-left: 4px;
	}
}
