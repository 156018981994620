@import 'commonsui/dist/css/_variables.scss';

:global {
	.selectCustom__control {
		border-radius: 1rem !important;
		background-color: transparent !important;
	}
	.selectCustom__control--is-focused {
		border-color: $primary !important;
		box-shadow: 0 0 0 1px $primary !important;
	}
	.selectCustom__menu {
		border-radius: 0 !important;
	}
	.selectCustom__option--is-focused {
		background-color: rgba($primary, 0.7) !important;
		color: $white !important;
	}
	.selectCustom__option--is-selected {
		background-color: $primary !important;
		color: $white !important;
	}
	.selectCustom__clear-indicator {
		cursor: pointer;
	}
}
