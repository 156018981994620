.autosuggest-highlight {
	font-weight: bold;
	color: black;
}

.underline-text {
	border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.underline-text:hover {
	text-decoration: none;
	border-bottom: 1px solid black;
}