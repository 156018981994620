@import 'commonsui/dist/css/_variables.scss';

.popularSearch {
	margin: 0rem 0.5rem 0.5rem;
	padding: 0.2rem 1rem;
	border-radius: 25px;
	border: solid $primary 0.2rem;
	color: black;
	box-shadow: none;
}
