@import 'commonsui/dist/css/_variables.scss';

.fullWidth {
	width: 100vw;
	margin-left: calc((100% - 100vw) / 2);
}

.newAndNoteworthiesBanner {
	margin-top: -29px;
}

.seeTour {
	display: inline-block;
	position: fixed;
	top: 390px;
	left: 0px;
	background-color: $gray-700;
	height: 30px;
	width: 30px;
	padding: 6px 9px;
	z-index: 1030;
}
