.shake-item {
	animation: shake 1.5s;
	animation-delay: 10s;
}

@keyframes shake {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(0.97);
	}
	50% {
		transform: scale(0.9);
	}
	75% {
		transform: scale(1.4);
	}
	85% {
		transform: scale(1.4);
	}
	100% {
		transform: scale(1);
	}
}
