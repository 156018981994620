@import 'commonsui/dist/css/_variables.scss';

.dropdown {
	padding: 0 10px;
	display: inline-block;
}

.dropdownDisabled span {
	color: $gray-800 !important;
}

.dropdown button {
	box-shadow: none !important;
	font-size: 16px;
	background: none !important;
	padding: 1px 0px;
}

.dropdown:hover {
	color: $gray-400 !important;
}

.button:hover {
	color: $gray-400 !important;
}

.dropdownDisabled,
.dropdownDisabled:hover {
	color: $gray-500 !important;
}

.dropdown > div {
	min-width: 105px;
	padding-left: 10px;
	padding-right: 10px;
	left: 16px !important;
	bottom: -5px !important;
}

.dropdownButton:hover,
.dropdownLink:hover {
	color: $white;
	background: $blue !important;
}

.dropdownButton {
	color: $gray-600;
	width: 30px;
	height: 30px;
	border-radius: 15px;
	font-size: 16px !important;
	text-align: center;
	margin: 0 6px;
}

.copyButton {
	color: $gray-600;
	background: $white;
	box-shadow: none;
	width: 32px;
	height: 32px;
	border-radius: 15px;
	margin-left: 5px;
	padding: 0;
}

.pptButton {
	color: $gray-600;
	background: $white;
	box-shadow: none;
	width: 32px;
	height: 32px;
	border-radius: 15px;
	margin-left: 2px;
	margin-right: 2px;
	padding: 0;
}

.spinnerContainer {
	text-align: center;
}

:global {
	.commentPopover .popover-body {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.actionLinks {
	text-align: left;
}

.detailsShareButtonSize {
	font-size: 16px;
}

.noVerticalPadding {
	padding-top: 0;
	padding-bottom: 0;
	transform: translateY(-0.1em);
}
