@import 'commonsui/dist/css/_variables.scss';

.contactModal {
	z-index: 10000;
	top: 45px;
}

.tagline {
	padding: 20px 0 28px;
	color: $gray-600 !important;
}

.contentTitle {
	color: $gray-600;
}

.buttonGroup {
	float: right;
	margin: 10px 0 15px;
}
