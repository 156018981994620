.autosuggestionContainer {
	position: absolute;
	width: 100%;
	background-color: white;
	opacity: 1 !important;
	max-height: 600px;
	overflow: auto;
}

.autosuggestionPlacement {
	margin-top: 2px;
	position: relative;
}

.resultLabel {
	text-transform: uppercase;
	font-weight: bold;
}

.autosuggestionFocused {
	background: #f8f9fa linear-gradient(180deg, #f9fafb, #f8f9fa) repeat-x;
}
