@import 'commonsui/dist/css/_variables.scss';

$animation-timing: 0.3s;
$animation-ease: ease-in-out;
$animation-ease-bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$gradient-overlay: linear-gradient(125deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
$base-image-url: 'https://s3.amazonaws.com/images.frontierview.com/fv3/report-series';

$report-series-images: (
	1: url('#{$base-image-url}/market-review/Argentina.png'),
	2: url('#{$base-image-url}/market-review/Brazil.png'),
	3: url('#{$base-image-url}/market-review/China.png'),
	4: url('#{$base-image-url}/market-review/Egypt.png'),
	5: url('#{$base-image-url}/market-review/Colombia.png'),
	6: url('#{$base-image-url}/market-review/Indonesia.png'),
	7: url('#{$base-image-url}/market-review/Mexico.png'),
	8: url('#{$base-image-url}/market-review/Turkey.png'),
	9: url('#{$base-image-url}/market-review/India.png'),
	10: url('#{$base-image-url}/market-review/Russia.png'),
	11: url('#{$base-image-url}/market-review/ASEAN.png'),
	12: url('#{$base-image-url}/market-review/GCC.png'),
	13: url('#{$base-image-url}/market-review/Venezuela.png'),
	14: url('#{$base-image-url}/market-review/WEUR.png'),
	16: url('#{$base-image-url}/mmm/MENA.png'),
	17: url('#{$base-image-url}/mmm/SSA.png'),
	18: url('#{$base-image-url}/mmm/WEUR.png'),
	20: url('#{$base-image-url}/mmm/China.png'),
	21: url('#{$base-image-url}/mmm/APAC.png'),
	22: url('#{$base-image-url}/mmm/Americas.png'),
	24: url('#{$base-image-url}/mmm/Central-Europe.png'),
	25: url('#{$base-image-url}/mmm/CIS.png'),
	26: url('#{$base-image-url}/market-review/Algeria.png'),
	27: url('#{$base-image-url}/market-review/Angola.png'),
	28: url('#{$base-image-url}/market-review/Australia.png'),
	29: url('#{$base-image-url}/market-review/Caribbean.png'),
	30: url('#{$base-image-url}/market-review/Chile.png'),
	31: url('#{$base-image-url}/market-review/Cote-dIvoire.png'),
	32: url('#{$base-image-url}/market-review/Ecuador.png'),
	33: url('#{$base-image-url}/market-review/Ethiopia.png'),
	34: url('#{$base-image-url}/market-review/Germany.png'),
	35: url('#{$base-image-url}/market-review/Ghana.png'),
	36: url('#{$base-image-url}/market-review/Hungary.png'),
	37: url('#{$base-image-url}/market-review/Iran.png'),
	38: url('#{$base-image-url}/market-review/Israel.png'),
	39: url('#{$base-image-url}/market-review/Japan.png'),
	40: url('#{$base-image-url}/market-review/Kazakhstan.png'),
	41: url('#{$base-image-url}/market-review/Kenya.png'),
	42: url('#{$base-image-url}/market-review/Kuwait.png'),
	43: url('#{$base-image-url}/market-review/Lebanon.png'),
	44: url('#{$base-image-url}/market-review/Morocco.png'),
	45: url('#{$base-image-url}/market-review/Mozambique.png'),
	46: url('#{$base-image-url}/market-review/Nigeria.png'),
	47: url('#{$base-image-url}/market-review/Peru.png'),
	48: url('#{$base-image-url}/market-review/Poland.png'),
	49: url('#{$base-image-url}/market-review/Qatar.png'),
	50: url('#{$base-image-url}/market-review/Saudi-Arabia.png'),
	51: url('#{$base-image-url}/market-review/Senegal.png'),
	52: url('#{$base-image-url}/market-review/South-Africa.png'),
	53: url('#{$base-image-url}/market-review/South-Korea.png'),
	54: url('#{$base-image-url}/market-review/UAE.png'),
	55: url('#{$base-image-url}/market-review/Ukraine.png'),
	56: url('#{$base-image-url}/market-review/Zambia.png'),
	57: url('#{$base-image-url}/market-review/Zimbabwe.png'),
	58: url('#{$base-image-url}/outlook/APAC.png'),
	59: url('#{$base-image-url}/outlook/ASEAN.png'),
	60: url('#{$base-image-url}/outlook/Caribbean.png'),
	61: url('#{$base-image-url}/outlook/Central-America.png'),
	62: url('#{$base-image-url}/outlook/Central-Europe.png'),
	63: url('#{$base-image-url}/outlook/CIS.png'),
	64: url('#{$base-image-url}/outlook/EMEA.png'),
	65: url('#{$base-image-url}/outlook/Global.png'),
	66: url('#{$base-image-url}/outlook/LATAM.png'),
	67: url('#{$base-image-url}/outlook/MENA.png'),
	68: url('#{$base-image-url}/outlook/SSA.png'),
	69: url('#{$base-image-url}/outlook/WEUR.png'),
	70: url('#{$base-image-url}/hc-outlook/APAC.png'),
	71: url('#{$base-image-url}/hc-market-review/Argentina.png'),
	72: url('#{$base-image-url}/hc-market-review/ASEAN.png'),
	73: url('#{$base-image-url}/hc-market-review/Australia.png'),
	74: url('#{$base-image-url}/hc-market-review/Brazil.png'),
	75: url('#{$base-image-url}/hc-market-review/Chile.png'),
	76: url('#{$base-image-url}/hc-market-review/China.png'),
	77: url('#{$base-image-url}/hc-market-review/Colombia.png'),
	78: url('#{$base-image-url}/hc-market-review/India.png'),
	79: url('#{$base-image-url}/hc-market-review/Japan.png'),
	80: url('#{$base-image-url}/hc-market-review/Mexico.png'),
	81: url('#{$base-image-url}/hc-market-review/Peru.png'),
	82: url('#{$base-image-url}/hc-market-review/Russia.png'),
	83: url('#{$base-image-url}/hc-market-review/Saudi.png'),
	84: url('#{$base-image-url}/hc-market-review/South-Korea.png'),
	85: url('#{$base-image-url}/hc-market-review/Turkey.png'),
	86: url('#{$base-image-url}/leadership-briefing/Global.png'),
	87: url('#{$base-image-url}/leadership-briefing/EMEA.png'),
	88: url('#{$base-image-url}/hc-leadership-briefing/LATAM-Funding-Outlook.png'),
	89: url('#{$base-image-url}/hc-leadership-briefing/LATAM-Key-Trends.png'),
	90: url('#{$base-image-url}/ahead-of-the-curve/Global-Events-To-Watch.png'),
	91: url('#{$base-image-url}/hc-ahead-of-the-curve/LATAM-Events-To-Watch.png'),
	92: url('#{$base-image-url}/hc-mmm/LATAM.png'),
	93: url('#{$base-image-url}/benchmarking/Mexico.png'),
	94: url('#{$base-image-url}/benchmarking/Global.png'),
	95: url('#{$base-image-url}/benchmarking/Turkey.png'),
	96: url('#{$base-image-url}/benchmarking/LATAM.png'),
	97: url('#{$base-image-url}/benchmarking/Russia.png'),
	98: url('#{$base-image-url}/ahead-of-the-curve/Global-Vaccination.png'),
	99: url('#{$base-image-url}/event-presentation/GlobalView.png'),
	100: url('#{$base-image-url}/market-review/Russias-Regions.png'),
	101: url('#{$base-image-url}/hc-leadership-briefing/APAC-Funding-Outlook.png'),
	102: url('#{$base-image-url}/hc-leadership-briefing/APAC-Key-Trends.png'),
	103: url('#{$base-image-url}/hc-ahead-of-the-curve/APAC-Events-To-Watch.png'),
	104: url('#{$base-image-url}/hc-mmm/APAC.png'),
	105: url('#{$base-image-url}/market-review/United-Kingdom.png'),
	106: url('#{$base-image-url}/hc-market-review/Poland.png'),
	107: url('#{$base-image-url}/hc-market-review/South-Africa.png'),
	108: url('#{$base-image-url}/hc-market-review/Algeria.png'),
	109: url('#{$base-image-url}/hc-market-review/UAE.png'),
	110: url('#{$base-image-url}/hc-market-review/Egypt.png'),
	111: url('#{$base-image-url}/hc-mmm/MEA.png'),
	112: url('#{$base-image-url}/oncology-market-review/Argentina.png'),
	113: url('#{$base-image-url}/oncology-market-review/Brazil.png'),
	114: url('#{$base-image-url}/oncology-market-review/Chile.png'),
	115: url('#{$base-image-url}/oncology-market-review/Colombia.png'),
	116: url('#{$base-image-url}/oncology-market-review/Mexico.png'),
	117: url('#{$base-image-url}/oncology-market-review/Peru.png'),
	118: url('#{$base-image-url}/hc-market-review/Central-Europe.png'),
	119: url('#{$base-image-url}/market-review/Spain.png'),
	120: url('#{$base-image-url}/market-review/France.png'),
	121: url('#{$base-image-url}/hc-market-review/Nigeria.png'),
	122: url('#{$base-image-url}/market-review/Romania.png'),
	123: url('#{$base-image-url}/hc-market-review/Kenya.png'),
	124: url('#{$base-image-url}/hc-market-review/Kuwait.png'),
	125: url('#{$base-image-url}/access-and-reimbursement/ASEAN-MedTech.png'),
	126: url('#{$base-image-url}/access-and-reimbursement/ASEAN-Pharma.png'),
	127: url('#{$base-image-url}/access-and-reimbursement/Australia-Pharma.png'),
	128: url('#{$base-image-url}/access-and-reimbursement/China-MedTech.png'),
	129: url('#{$base-image-url}/access-and-reimbursement/China-Pharma.png'),
	130: url('#{$base-image-url}/access-and-reimbursement/India-MedTech.png'),
	131: url('#{$base-image-url}/access-and-reimbursement/India-Pharma.png'),
	132: url('#{$base-image-url}/access-and-reimbursement/Japan-MedTech.png'),
	133: url('#{$base-image-url}/access-and-reimbursement/Japan-Pharma.png'),
	134: url('#{$base-image-url}/access-and-reimbursement/South-Korea-MedTech.png'),
	135: url('#{$base-image-url}/access-and-reimbursement/South-Korea-Pharma.png'),
	136: url('#{$base-image-url}/access-and-reimbursement/Argentina-MedTech.png'),
	137: url('#{$base-image-url}/access-and-reimbursement/Argentina-Pharma.png'),
	138: url('#{$base-image-url}/access-and-reimbursement/Brazil-MedTech.png'),
	139: url('#{$base-image-url}/access-and-reimbursement/Brazil-Pharma.png'),
	140: url('#{$base-image-url}/access-and-reimbursement/Chile-MedTech.png'),
	141: url('#{$base-image-url}/access-and-reimbursement/Chile-Pharma.png'),
	142: url('#{$base-image-url}/access-and-reimbursement/Colombia-MedTech.png'),
	143: url('#{$base-image-url}/access-and-reimbursement/Colombia-Pharma.png'),
	144: url('#{$base-image-url}/access-and-reimbursement/Mexico-MedTech.png'),
	145: url('#{$base-image-url}/access-and-reimbursement/Mexico-Pharma.png'),
	146: url('#{$base-image-url}/access-and-reimbursement/Peru-MedTech.png'),
	147: url('#{$base-image-url}/access-and-reimbursement/Peru-Pharma.png'),
	148: url('#{$base-image-url}/hc-monitors/APAC-MedTech.png'),
	149: url('#{$base-image-url}/hc-monitors/APAC-Pharma.png'),
	150: url('#{$base-image-url}/hc-monitors/LATAM-MedTech.png'),
	151: url('#{$base-image-url}/hc-monitors/LATAM-Pharma.png'),
	152: url('#{$base-image-url}/hc-monitors/MEA-Medtech.png'),
	153: url('#{$base-image-url}/hc-monitors/MEA-Pharma.png'),
	154: url('#{$base-image-url}/sector-reviews/Australia-Private.png'),
	155: url('#{$base-image-url}/sector-reviews/Australia-Public.png'),
	156: url('#{$base-image-url}/sector-reviews/China-Public.png'),
	157: url('#{$base-image-url}/sector-reviews/India-Private-Hospitals.png'),
	158: url('#{$base-image-url}/sector-reviews/India-Private-Labs.png'),
	159: url('#{$base-image-url}/sector-reviews/India-Public.png'),
	160: url('#{$base-image-url}/sector-reviews/Indonesia-Private.png'),
	161: url('#{$base-image-url}/sector-reviews/Indonesia-Public.png'),
	162: url('#{$base-image-url}/sector-reviews/Japan-Public.png'),
	163: url('#{$base-image-url}/sector-reviews/Malaysia-Private.png'),
	164: url('#{$base-image-url}/sector-reviews/Malaysia-Public.png'),
	165: url('#{$base-image-url}/sector-reviews/Philippines-Private.png'),
	166: url('#{$base-image-url}/sector-reviews/Philippines-Public.png'),
	167: url('#{$base-image-url}/sector-reviews/South-Korea-Public.png'),
	168: url('#{$base-image-url}/sector-reviews/Thailand-Private.png'),
	169: url('#{$base-image-url}/sector-reviews/Thailand-Public.png'),
	170: url('#{$base-image-url}/sector-reviews/Vietnam-Private.png'),
	171: url('#{$base-image-url}/sector-reviews/Vietnam-Public.png'),
	172: url('#{$base-image-url}/sector-reviews/Russia-Public.png'),
	173: url('#{$base-image-url}/sector-reviews/Argentina-Private.png'),
	174: url('#{$base-image-url}/sector-reviews/Argentina-Public.png'),
	175: url('#{$base-image-url}/sector-reviews/Brazil-Private.png'),
	176: url('#{$base-image-url}/sector-reviews/Brazil-Public.png'),
	177: url('#{$base-image-url}/sector-reviews/Chile-Private.png'),
	178: url('#{$base-image-url}/sector-reviews/Chile-Public.png'),
	179: url('#{$base-image-url}/sector-reviews/Colombia-Public.png'),
	180: url('#{$base-image-url}/sector-reviews/Mexico-Private.png'),
	181: url('#{$base-image-url}/sector-reviews/Mexico-Public.png'),
	182: url('#{$base-image-url}/sector-reviews/Peru-Public.png'),
	183: url('#{$base-image-url}/sector-reviews/Algeria-Public.png'),
	184: url('#{$base-image-url}/sector-reviews/Egypt-Private.png'),
	185: url('#{$base-image-url}/sector-reviews/Egypt-Public.png'),
	186: url('#{$base-image-url}/sector-reviews/Kuwait-Public.png'),
	187: url('#{$base-image-url}/sector-reviews/Nigeria-Public.png'),
	188: url('#{$base-image-url}/sector-reviews/Saudi-Arabia-Private.png'),
	189: url('#{$base-image-url}/sector-reviews/Saudi-Arabia-Public.png'),
	190: url('#{$base-image-url}/sector-reviews/Turkiye-Private.png'),
	191: url('#{$base-image-url}/sector-reviews/Turkiye-Public.png'),
	192: url('#{$base-image-url}/sector-reviews/UAE-Private.png'),
	193: url('#{$base-image-url}/sector-reviews/UAE-Public.png'),
	194: url('#{$base-image-url}/sector-reviews/South-Africa-Private.png'),
	195: url('#{$base-image-url}/sector-reviews/South-Africa-Public.png'),
	196: url('#{$base-image-url}/sector-reviews/Kenya-Public.png'),
	197: url('#{$base-image-url}/market-review/Iraq.png'),
	198: url('#{$base-image-url}/consumer-view/USA.png'),
	199: url('#{$base-image-url}/consumer-view/Brazil.png'),
	200: url('#{$base-image-url}/consumer-view/Mexico.png'),
	201: url('#{$base-image-url}/consumer-view/Colombia.png'),
	202: url('#{$base-image-url}/consumer-view/Chile.png'),
	203: url('#{$base-image-url}/consumer-view/Peru.png'),
	204: url('#{$base-image-url}/consumer-view/Argentina.png'),
	205: url('#{$base-image-url}/consumer-view/Turkey.png'),
	206: url('#{$base-image-url}/consumer-view/Saudi.png'),
	207: url('#{$base-image-url}/consumer-view/UAE.png'),
	208: url('#{$base-image-url}/consumer-view/South-Africa.png'),
	209: url('#{$base-image-url}/consumer-view/Egypt.png'),
	210: url('#{$base-image-url}/consumer-view/Germany.png'),
	211: url('#{$base-image-url}/consumer-view/Spain.png'),
	212: url('#{$base-image-url}/consumer-view/France.png'),
	213: url('#{$base-image-url}/consumer-view/United-Kingdom.png'),
	214: url('#{$base-image-url}/consumer-view/Italy.png'),
	215: url('#{$base-image-url}/consumer-view/Poland.png'),
	216: url('#{$base-image-url}/consumer-view/Kazakhstan.png'),
	217: url('#{$base-image-url}/hc-monitors/WEUR-MedTech.png'),
	218: url('#{$base-image-url}/hc-monitors/WEUR-Pharma.png'),
	219: url('#{$base-image-url}/consumer-view/Canada.png'),
	220: url('#{$base-image-url}/market-review/Congo.png'),
);

@each $series-type, $image in $report-series-images {
	$bg-image: $image;
	@if ($series-type >= 1 and $series-type <= 14) or
		($series-type >= 26 and $series-type <= 57) or
		($series-type == 100) or
		($series-type == 105) or
		($series-type >= 119 and $series-type <= 120) or
		($series-type == 122) or
		($series-type == 197)
	{
		$bg-image: $gradient-overlay, $image;
	}
	.report-image-#{$series-type} {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-image: $bg-image;
	}
	[class*='stacked--#{$series-type}-'] {
		position: relative;
		transition: transform $animation-timing $animation-ease;
		will-change: transform;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: transform $animation-timing $animation-ease;
			will-change: transform;
		}

		&:before {
			z-index: -1;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: $bg-image;
			opacity: 0.8;
		}

		&:after {
			z-index: -2;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: $bg-image;
			opacity: 0.6;
		}
	}

	.stacked--#{$series-type}-down {
		&:before,
		&:after {
			transform-origin: center top;
		}

		&:hover {
			transform: translate(0, 5px);

			&:before {
				transform: translate(0, -5px) scale(0.95);
			}
			&:after {
				transform: translate(0, -10px) scale(0.9);
			}
		}
	}
}

@for $i from 1 through 10 {
	$start-color: $indigo;
	$end-color: $purple;
	@if $i == 9 {
		// event presentation
		$start-color: $lime;
		$end-color: $green;
	}
	.gradient#{$i} {
		background-image: linear-gradient(45deg, $start-color 0%, $end-color 100%);
	}
}
