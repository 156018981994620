@import 'commonsui/dist/css/_variables.scss';

.grayHeader {
	color: $gray-600;
}

.filterColumn {
	margin-top: auto;
	margin-bottom: auto;
}

.searchIcon {
	align-items: center;
	bottom: 16px;
	display: flex;
	justify-content: center;
	position: absolute;
	z-index: 10;
	font-size: 20px;
	color: #666;
}

.searchClear {
	right: 0;
	cursor: pointer;
}

.searchClear:hover {
	opacity: 0.5 !important;
}

.searchInput {
	font-size: 1.2rem;
	padding-left: 30px !important;
}

.fixedSearch {
	position: -webkit-sticky; /* Safari & IE */
	position: sticky;
	top: 36px;
	left: 0;
	right: 0;
	background-color: $app-background;
	z-index: 1020;
}

.searchResults {
	position: relative;
	top: 18px;
}

.inlineSearchResults {
	position: relative;
	margin-top: 1.5rem;
	margin-left: -15px;
	margin-right: -15px;
}

@media (min-width: 768px) {
	.fixedSearch {
		top: 71px;
	}
}

@media (min-width: 576px) {
	.searchResults {
		top: 8px;
	}
}
