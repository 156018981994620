@import 'commonsui/dist/css/_variables.scss';

.header {
	background-color: $teal;
	text-align: center;
	padding: 15px;
}

.header img {
	width: 300px;
}
