.newsImageLoader {
	width: 100%;
	padding-top: 66.66%;
}

.newsImage {
	margin-left: -1.25rem;
	margin-right: -1.25rem;
}

.pointer {
	cursor: pointer;
}

.cardIcon {
	margin-top: 6px;
}

.whiteRow {
	border-top: 1px solid white;
}

.cardContainer {
	transition: transform 0.3s;
	-webkit-transition: transform 0.3s;
	-o-transition: transform 0.3s;
	border: 0;
}

.cardContainer:hover {
	transform: scale(1.02);
}

.marketMedia h4 {
	display: inline;
}

.footerOverlay {
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	padding: 10px 15px;
}
