@import 'commonsui/dist/css/_variables.scss';

.chartHeader {
	padding: 15px 20px;
}

.indicatorItalic {
	font-style: italic;
}

.chartWrapper {
	padding: 3px;
	height: 300px;
}
