@import 'commonsui/dist/css/_variables.scss';

:global {
	body {
		background-color: $app-background;
	}
}

.mainContainer {
	margin-top: 69px;
}

@media (min-width: 768px) {
	.mainContainer {
		margin-top: 100px;
	}
}
